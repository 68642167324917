import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, Divider, MobileStepper, Typography } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PlaceIcon from '@mui/icons-material/Place';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import projectData from "../data/projects.json"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProjectCard = ({ index }) => {
    const [activeStep, setActiveStep] = useState(0);
    useEffect(() => {
        setActiveStep(0);
    }, [index]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep+1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep-1);
    };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ height: '100%' }}>
            <Card variant="outlined" sx={{ width: '100%', minHeight: '100%' }}>
                {
                    index === null ? null :
                    <CardMedia>
                        <Box>
                            <AutoPlaySwipeableViews axis='x' index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                                {
                                    projectData[index].pictures.map((picture, idx) => (
                                        <div key={idx}>
                                            {
                                                Math.abs(activeStep - idx) > 2 ? null :
                                                <Box component="img" sx={{ overflow: 'hidden', display: 'block', width: '100%' }} src={picture} alt="Picture caption" />
                                            }
                                        </div>
                                    ))
                                }
                            </AutoPlaySwipeableViews>
                            <MobileStepper steps={projectData[index].pictures.length} position="static" activeStep={activeStep}
                                nextButton={
                                    <Button size="small" disabled={activeStep === projectData[index].pictures.length - 1} onClick={handleNext}>
                                        Next <KeyboardArrowRight />
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" disabled={activeStep === 0} onClick={handleBack}>
                                        <KeyboardArrowLeft /> Back
                                    </Button>
                                }
                            />
                            <Divider />
                        </Box>
                    </CardMedia>
                }
                <CardContent>
                    {
                        index === null ?
                        <Box>
                            <Typography variant="h4" component="h4">
                                Projects Undertaken
                            </Typography>
                            <Divider sx={{ marginTop: '8px' }} />
                            <Typography component="div" sx={{ marginTop: '8px' }}>
                                Zoom in, pan and click on any of the markers on the map to get started!
                            </Typography>
                        </Box> : 
                        <Box>
                            <Typography variant="h4" component="h4">
                                { projectData[index].name }
                            </Typography>
                            <Divider sx={{ marginTop: '8px' }} />
                            <Typography component="div" sx={{ marginTop: '8px', display: 'flex' }}>
                                <PlaceIcon sx={{ fontSize: '1rem', marginTop: '2px', marginRight: '8px' }} />
                                <span>{ projectData[index].location }</span>
                            </Typography>
                            <Typography component="div" sx={{ marginTop: '8px', display: 'flex' }}>
                                <InfoOutlinedIcon sx={{ fontSize: '1rem', marginTop: '2px', marginRight: '8px' }} />
                                <span>{ projectData[index].description }</span>
                            </Typography>
                        </Box>
                    }
                </CardContent>
            </Card>
        </Box>
    );
};

export default ProjectCard;