import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { Box } from "@mui/material";

import Home from "./pages/home";
import Products from "./pages/products";
import Projects from "./pages/projects";
import Gallery from "./pages/gallery";
import CSR from "./pages/csr";
import Careers from "./pages/careers";
import Contact from "./pages/contact";

import Navbar from "./components/navbar";
import Footer from "./components/footer";

import { lightMode, darkMode } from "./theme";
import useLocalStorage from "./hooks/useLocalStorage";

const App = () => {
    const [theme, setTheme] = useLocalStorage("theme", true);

    return (
        <Router>
            <ThemeProvider theme={theme ? lightMode : darkMode}>
                <CssBaseline />
                <Navbar theme={theme} setTheme={setTheme} />
                <Box sx={{ width: '100vw', maxWidth: '1400px', margin: '0px auto', padding: '20px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/products" element={<Products />} />
                        <Route exact path="/projects" element={<Projects />} />
                        <Route exact path="/gallery" element={<Gallery />} />
                        <Route exact path="/csr" element={<CSR />} />
                        <Route exact path="/careers" element={<Careers />} />
                        <Route exact path="/contact" element={<Contact />} />
                    </Routes>
                </Box>
                <Footer />
            </ThemeProvider>
        </Router>
    );
};

export default App