import React from "react";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
    return (
        <Box>
            <AppBar position="static" sx={{ alignItems: 'center', padding: '10px 0px' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton href="tel:+919811153053" size="large" edge="end" color="inherit" sx={{ margin: 0 }}>
                        <PhoneIcon />
                    </IconButton>
                    <IconButton href="mailto:info@trivium.co.in" size="large" edge="end" color="inherit" sx={{ margin: 0 }}>
                        <EmailIcon />
                    </IconButton>
                    <IconButton href="https://www.facebook.com/triviumpowerengineers" target="_blank" size="large" edge="end" color="inherit" sx={{ margin: 0 }}>
                        <FacebookIcon />
                    </IconButton>
                    <IconButton href="https://instagram.com/triviumpowerengineers" target="_blank" size="large" edge="end" color="inherit" sx={{ margin: 0 }}>
                        <InstagramIcon />
                    </IconButton>
                    <IconButton href="https://www.linkedin.com/in/triviumpowerengineers/" target="_blank" size="large" edge="end" color="inherit" sx={{ margin: 0 }}>
                        <LinkedInIcon />
                    </IconButton>
                    <IconButton href="https://twitter.com/triviumpower" target="_blank" size="large" edge="end" color="inherit" sx={{ margin: 0 }}>
                        <TwitterIcon />
                    </IconButton>
                </Toolbar>
                <Toolbar sx={{ marginTop: "-10px" }}>
                    <Typography variant="h6" component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
                        <span>&#169; Trivium Power Engineers Pvt Ltd</span>
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Footer