import React, { useState } from "react";
import { Box, Divider, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, FreeMode } from "swiper";

import gallery from "../data/gallery.json"

import "swiper/css";
import "swiper/css/pagination";

const Gallery = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isModelOpen, setModalOpen] = useState(false);
    const [modalSlide, setModalSlide] = useState(gallery[0]);

    const handleOpen = (slide) => {
        setModalOpen(true);
        setModalSlide(slide);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Modal
                open={isModelOpen}
                onClose={handleClose}
            >
                <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", p: 2, bgcolor: "background.paper", borderRadius: 2, width: 'calc(100% - 40px)', maxWidth: '1200px', height: isMobile ? 'fit-content' : '100%', maxHeight: '600px' }}>
                    <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} width="100%" height="100%">
                        <Box>
                            <img loading="lazy" src={modalSlide.src} style={{ width: '100%', maxHeight: '100%', maxWidth: '800px' }} alt={modalSlide.name} />
                        </Box>
                        <Divider orientation={ isMobile ? "horizontal" : "vertical" } flexItem sx={ isMobile ? { margin: '16px 0' } : { margin: '0 16px' } } />
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" component="h6">
                                { modalSlide.name }
                            </Typography>
                            {
                                isMobile ? null :
                                <Divider sx={{ margin: '8px 0' }} />
                            }
                            <Box>
                                { modalSlide.description }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            
            <Box component="h2" sx={{ marginBottom: '8px' }}>
                Gallery 1
            </Box>
            <Swiper
                style={{ width: '100%', aspectRatio: isMobile ? "3/1" : "4/1" }}
                effect="coverflow"
                grabCursor={true}
                spaceBetween={ isMobile ? 4 : 10 }
                centeredSlides={true}
                slidesPerView={2}
                freeMode={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    reverseDirection: false
                }}
                modules={[Autoplay, FreeMode]}
                loop={true}
                className="swiper"
                rev="true"
            >
                {
                    gallery.map((slide, idx) => (
                        <SwiperSlide key={idx} onClick={() => handleOpen(slide)} style={{ cursor: 'pointer' }}>
                            <img loading="lazy" src={slide.src} style={{ width: '100%', height: '100%', aspectRatio: "4/3", objectFit: 'cover' }} alt={slide.name} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

            <Box component="h2" sx={{ marginBottom: '8px' }}>
                Gallery 2
            </Box>
            <Swiper
                style={{ width: '100%', aspectRatio: isMobile ? "3/1" : "4/1" }}
                effect="coverflow"
                grabCursor={true}
                spaceBetween={ isMobile ? 4 : 10 }
                centeredSlides={true}
                slidesPerView={2}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    reverseDirection: true
                }}
                modules={[Autoplay]}
                loop={true}
                className="swiper"
                rev="true"
            >
                {
                    gallery.map((slide, idx) => (
                        <SwiperSlide key={idx} onClick={() => handleOpen(slide)} style={{ cursor: 'pointer' }}>
                            <img loading="lazy" src={slide.src} style={{ width: '100%', height: '100%', aspectRatio: "4/3", objectFit: 'cover' }} alt={slide.name} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

            <Box component="h2" sx={{ marginBottom: '8px' }}>
                Gallery 3
            </Box>
            <Swiper
                style={{ width: '100%', aspectRatio: isMobile ? "3/1" : "4/1" }}
                effect="coverflow"
                grabCursor={true}
                spaceBetween={ isMobile ? 4 : 10 }
                centeredSlides={true}
                slidesPerView={2}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    reverseDirection: false
                }}
                modules={[Autoplay]}
                loop={true}
                className="swiper"
                rev="true"
            >
                {
                    gallery.map((slide, idx) => (
                        <SwiperSlide key={idx} onClick={() => handleOpen(slide)} style={{ cursor: 'pointer' }}>
                            <img loading="lazy" src={slide.src} style={{ width: '100%', height: '100%', aspectRatio: "4/3", objectFit: 'cover' }} alt={slide.name} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </Box>
    );
};

export default Gallery;