import React, { useState } from "react";
import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";

import Map from "../components/map";
import ProjectCard from "../components/projectCard";

const Projects = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [index, setIndex] = useState(null);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} sx={{ height: '100%' }}>
                <Box flex={1}>
                    <Map strokeColor={theme.palette.text.primary} markerColor={theme.palette.info.main} setIndex={setIndex} />
                </Box>
                <Divider orientation={ isMobile ? "horizontal" : "vertical" } flexItem sx={ isMobile ? { margin: '16px 0' } : { margin: '0 16px' } } />
                <Box flex={1}>
                    <ProjectCard index={index} />
                </Box>
            </Box>
        </Box>
    );
};

export default Projects;