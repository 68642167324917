import React from "react";
import { AppBar, Box, Card, CardContent, Grid } from "@mui/material";

const Home = () => {
    return (
        <Box>
            <Box component="h2" sx={{ display: 'flex', justifyContent: 'center' }}>
                TRIVIUM POWER ENGINEERS
            </Box>
            <p>
                At Trivium Power Engineers Pvt Ltd, we embody the essence of our name - "the three ways" or "the three roads" - as we pave the path towards excellence in engineering solutions. Since our establishment in 2003, we have evolved into a dynamic company with four distinct verticals: Products, Projects, Spares & Services. With a dedicated team of engineering professionals and technocrats, we offer comprehensive turnkey solutions and services to meet the diverse needs of our clients.
            </p>

            <Box component="h3" sx={{ marginTop: '8px' }}>
                Our Vision
            </Box>
            <p>
                At Trivium Power Engineers Pvt Ltd, our vision is to be a leading force in engineering solutions, driving innovation and excellence across industries. We strive to be recognized as a trusted partner, delivering sustainable and technologically advanced solutions that meet the evolving needs of our clients. By embracing the power of collaboration, continuous improvement, and customer-centricity, we aim to build long-term relationships and create a positive impact on the world through our engineering endeavors. With a relentless commitment to quality, integrity, and professionalism, we envision a future where Trivium Power Engineers is synonymous with outstanding engineering solutions that shape a brighter tomorrow.
            </p>

            <Box sx={{ marginTop: '16px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Card variant="outlined" sx={{ width: '100%' }}>
                            <CardContent>
                                <AppBar position="relative" sx={{ width: 'calc(100% + 32px)', marginLeft: '-16px', marginTop: '-16px', p: 1.5 }}>
                                    Unparalleled Engineering Solutions
                                </AppBar>
                                <p>
                                    With a rich history spanning over two decades, Trivium Power Engineers has established itself as a trusted name in the engineering industry. Our commitment to excellence and customer satisfaction drives us to provide unparalleled solutions across various sectors. Whether you require state-of-the-art products, efficient project execution, high-quality spares, or reliable services, our strategic partnerships allow us to offer a comprehensive range of solutions tailored to your specific requirements.
                                </p>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Card variant="outlined" sx={{ width: '100%' }}>
                            <CardContent>
                                <AppBar position="relative" sx={{ width: 'calc(100% + 32px)', marginLeft: '-16px', marginTop: '-16px', p: 1.5 }}>
                                    Collaboration for Success
                                </AppBar>
                                <p>
                                    Our strategic alliances with esteemed companies like Thermax, Grundfos, Flowjet valves, and others are rooted in a shared vision of delivering exceptional engineering solutions. These partnerships enable us to combine our expertise with their industry-leading technologies, products, and services, resulting in enhanced value for our clients. Through collaboration and knowledge-sharing, we strive to push the boundaries of engineering excellence and drive innovation in every project we undertake.
                                </p>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Card variant="outlined" sx={{ width: '100%' }}>
                            <CardContent>
                                <AppBar position="relative" sx={{ width: 'calc(100% + 32px)', marginLeft: '-16px', marginTop: '-16px', p: 1.5 }}>
                                    End-to-End Solutions
                                </AppBar>
                                <p>
                                    Trivium Power Engineers is your one-stop destination for end-to-end engineering solutions. From conceptualization to implementation, we offer comprehensive services that encompass system design, supply, installation, execution, commissioning, and personnel training. Our dedicated team of engineering professionals and technocrats works closely with our partners to ensure seamless integration of their products and technologies, guaranteeing optimal performance and efficiency for your operations.
                                </p>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Card variant="outlined" sx={{ width: '100%' }}>
                            <CardContent>
                                <AppBar position="relative" sx={{ width: 'calc(100% + 32px)', marginLeft: '-16px', marginTop: '-16px', p: 1.5 }}>
                                    Customer-Centric Approach
                                </AppBar>
                                <p>
                                    At Trivium Power Engineers, we place our clients at the center of everything we do. We understand that each project is unique, and our customer-centric approach allows us to tailor our solutions to meet your specific requirements. Our team of experts will collaborate closely with you to understand your goals, challenges, and aspirations, ensuring that our solutions align perfectly with your vision. We believe in building long-term relationships based on trust, transparency, and exceptional service.
                                </p>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* <Box component="h3" sx={{ marginTop: '8px' }}>
                Channel Partners
            </Box> */}
        </Box>
    );
};

export default Home