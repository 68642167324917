import React, { useState } from "react";
import { Box, Button, Card, CardContent, Container, Divider, FormControl, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

import { SCRIPT_URL } from "../config";

const Contact = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [postData, setPostData] = useState({
        name: '',
        phone: '',
        email: '',
        company: '',
        message: ''
    });
    const [mobileError, setMobileError] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPostData({
            ...postData,
            [name]: value
        });
        if (name === "phone"){
            if (value.length === 10)
                setMobileError(false);
            else
                setMobileError(true);
        }
    };

    const handleWhatsAppSubmit = (e) => {
        e.preventDefault();
        const form = e.target.form;
        if (!form.checkValidity()){
            form.reportValidity();
        }
        if (postData.phone.length !== 10){
            alert("Phone Number invalid!");
            return
        }
        handleSubmit(e);
        const sendMessage = `Hello Mr. Badonia. Myself, ${postData.name} representing ${postData.company}. I filled the form on your website and below is my message: ${postData.message}. Please contact me on my phone number: ${postData.phone} or my email: ${postData.email}.`;
        console.log(sendMessage);
        window.location.href = `https://wa.me/9811153053?text=${sendMessage}`;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (postData.phone.length !== 10){
            alert("Phone Number invalid!");
            return
        }
        fetch(SCRIPT_URL, {
            method: 'POST',
            body: JSON.stringify(postData),
            mode: 'no-cors'
        })
    };

    return (
        <Box>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                <Box flex={1}>
                    <Box>
                        <Typography variant="h4" component="h4" sx={{ display: 'flex', justifyContent: 'center' }} gutterBottom>
                            Send us a message
                        </Typography>
                        <form style={{ padding: '16px', marginTop: '-10px' }} onSubmit={handleSubmit}>
                            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                                <TextField value={postData.name} onChange={handleChange} label="Name" name="name" type="text" required />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                                <TextField value={postData.company} onChange={handleChange} label="Company" name="company" type="text" required />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                                <TextField value={postData.email} onChange={handleChange} label="Email" name="email" type="email" required />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: '16px', display: 'flex', flexDirection: 'row' }}>
                                <TextField value="+91" disabled sx={{ width: '60px', marginRight: '12px' }} />
                                <TextField value={postData.phone} onChange={handleChange} label="Mobile Number" name="phone" type="number" required sx={{ flexGrow: 1 }} />
                            </FormControl>
                            {
                                mobileError === false ? null :
                                <Typography sx={{ marginTop: "-4px", marginBottom: "16px", border: '1px solid', borderColor: 'error.main', color: 'error.main', p: 1, borderRadius: 1 }}>
                                    Please enter a valid mobile number
                                </Typography>
                            }
                            <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                                <TextField value={postData.message} onChange={handleChange} label="Message" name="message" type="text" multiline rows={6} required />
                            </FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button type="submit" variant="outlined" onClick={handleWhatsAppSubmit} sx={{ marginRight: '6px', borderColor: '#25D366', color: '#25D366' }} id="whatsapp">
                                    WhatsApp
                                    <WhatsAppIcon color="inherit" sx={{ marginLeft: '8px' }} />
                                </Button>
                                <Button type="submit" variant="contained" sx={{ position: 'relative', marginLeft: '6px' }} id="submit">
                                    Submit
                                    <SendIcon color="inherit" sx={{ marginLeft: '8px', fontSize: '1rem' }} />
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
                <Divider orientation={ isMobile ? "horizontal" : "vertical" } flexItem />
                <Box flex={1} sx={ isMobile ? { margin: '16px 0' } : null }>
                    <Box>
                        <Typography variant="h4" component="h4" sx={{ display: 'flex', justifyContent: 'center' }} gutterBottom>
                            Give us a visit
                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'row', fontSize: '1.1rem' }}>
                            <Box sx={{ marginRight: '15px' }}>
                                <PlaceIcon />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <b>Trivium Power Engineers Pvt. Ltd.</b><br />
                                F-73, Sector 11<br />
                                Noida, Uttar Pradesh - 201301
                            </Box>
                        </Container>
                        <Container sx={{ display: 'flex', flexDirection: 'row', marginTop: '8px', fontSize: '1.1rem' }}  href="tel:+911204282845">
                            <Box sx={{ marginRight: '15px' }}>
                                <PhoneIcon />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <b>+91 120 4282845 / 46 / 47</b><br />
                            </Box>
                        </Container>
                        <Container sx={{ display: 'flex', flexDirection: 'row', marginTop: '8px', fontSize: '1.1rem' }}  href="tel:+911204282845">
                            <Box sx={{ marginRight: '15px' }}>
                                <PhoneIphoneIcon />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <b>+91 9811153053 / 9811943161 / 9811943162</b><br />
                            </Box>
                        </Container>
                        <Container sx={{ display: 'flex', flexDirection: 'row', marginTop: '8px', fontSize: '1.1rem' }}  href="mailto:info@trivium.co.in">
                            <Box sx={{ marginRight: '15px' }}>
                                <EmailIcon />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <b>info@trivium.co.in</b><br />
                            </Box>
                        </Container>
                        <Container sx={{ marginTop: '20px' }}>
                            <iframe title="GMap Location" style={{ width: "100%", minHeight: "300px" }} frameBorder="0" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?hl=en&amp;q=Trivium Power Engineers&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        </Container>
                    </Box>
                </Box>
            </Box>

            <Divider />

            <Box>
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h4" component="h4" sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        Social Media
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '16px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} component="a" href="https://www.facebook.com/triviumpowerengineers" target="_blank">
                                <Card variant="outlined" style={{ width: "100%" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                        <FacebookIcon sx={{ marginTop: '4px', marginRight: '10px' }} />
                                        <Typography variant="h6" component="h6" flexGrow={1}>
                                            @triviumpowerengineers
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} component="a" href="https://instagram.com/triviumpowerengineers" target="_blank">
                                <Card variant="outlined" style={{ width: "100%" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                        <InstagramIcon sx={{ marginTop: '4px', marginRight: '10px' }} />
                                        <Typography variant="h6" component="h6" flexGrow={1}>
                                            @triviumpowerengineers
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} component="a" href="https://www.linkedin.com/in/triviumpowerengineers/" target="_blank">
                                <Card variant="outlined" style={{ width: "100%" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                        <LinkedInIcon sx={{ marginTop: '4px', marginRight: '10px' }} />
                                        <Typography variant="h6" component="h6" flexGrow={1}>
                                            @triviumpowerengineers
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} component="a" href="https://twitter.com/triviumpower" target="_blank">
                                <Card variant="outlined" style={{ width: "100%" }}>
                                    <CardContent sx={{ display: "flex", flexDirection: "row" }}>
                                        <TwitterIcon sx={{ marginTop: '4px', marginRight: '10px' }} />
                                        <Typography variant="h6" component="h6" flexGrow={1}>
                                            @triviumpower
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Contact;