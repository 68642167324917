import React from "react";
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from "@mui/material";

import InfoIcon from '@mui/icons-material/Info';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SchoolIcon from '@mui/icons-material/School';

import jobs from "../data/jobs.json"

const Careers = () => {

    return (
        <Box>
            <Box component="h2">
                Available Jobs
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '16px' }}>
                <Grid container spacing={2}>
                    {
                        jobs.map((job, idx) => (
                            <Grid key={idx} item xs={12} sm={12} md={4} sx={{ marginBottom: '0' }}>
                                <Card variant="outlined" sx={{ width: '100%', height: 'max-content' }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
                                        <Typography variant="h5" component="div" sx={{ color: "primary.main" }}>
                                            { job.position }
                                        </Typography>
                                        <Divider sx={{ margin: '8px 0' }} />
                                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }} component="div">
                                            Job description
                                        </Typography>
                                        <Typography component="div" sx={{ display: 'flex', justifyContent: 'center', fontSize: '1rem', marginTop: '4px' }}>
                                            <InfoIcon sx={{ fontSize: '1rem', marginTop: '2px', marginRight: '8px' }} /> 
                                            <div style={{ flexGrow: 1 }}>{ job.description }</div>
                                        </Typography>
                                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '8px' }} component="div">
                                            Experience Required
                                        </Typography>
                                        <Typography component="div" sx={{ display: 'flex', justifyContent: 'center', fontSize: '1rem', marginTop: '4px' }}>
                                            <BusinessCenterIcon sx={{ fontSize: '1rem', marginTop: '2px', marginRight: '8px' }} /> 
                                            <div style={{ flexGrow: 1 }}>{ job.required.experience }</div>
                                        </Typography>
                                        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '8px' }} component="div">
                                            Educational qualifications
                                        </Typography>
                                        <Typography component="div" sx={{ display: 'flex', justifyContent: 'center', fontSize: '1rem', marginTop: '4px' }}>
                                            <SchoolIcon sx={{ fontSize: '1rem', marginTop: '2px', marginRight: '8px' }} /> 
                                            <div style={{ flexGrow: 1 }}>{ job.required.degree }</div>
                                        </Typography>
                                        <Divider sx={{ margin: '8px 0' }} />
                                        <Button variant="outlined" href={ job.link } sx={{ marginBottom: '-4px', maxWidth: '150px' }}>Apply Now</Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </Box>
    );
};

export default Careers;