import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";

import HomeIcon from '@mui/icons-material/Home';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import markers from "../data/markers.json"

const Map = ({ strokeColor, markerColor, setIndex }) => {
    const MIN_ZOOM = 10;
    const MAX_ZOOM = 1000;

    const [scaleFactor, setScaleFactor] = useState(10);
    const [position, setPosition] = useState({
        coordinates: [83, 22],
        zoom: 10
    });
    const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/india/india-";

    const handleClick = (markerIndex) => {
        if (typeof markerIndex === 'number'){
            setIndex(markerIndex);
        }
    };

    const handleZoom = (marker) => {
        setScaleFactor(marker.zoom);
        setPosition({
            coordinates: marker.coordinates,
            zoom: marker.zoom
        });
        setIndex(null);
    };

    const handleHome = () => {
        setScaleFactor(10);
        setPosition({
            coordinates: [83, 22],
            zoom: 10
        });
    };

    const handleZoomIn = () => {
        if (position.zoom > MAX_ZOOM / 2) {
            setPosition((pos) => ({ ...pos, zoom: MAX_ZOOM }));
            setScaleFactor(MAX_ZOOM);
        } else {
            setScaleFactor((prevScaleFactor) => (prevScaleFactor * 2));
            setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
        }
    };

    const handleZoomOut = () => {
        if (position.zoom < MIN_ZOOM * 2) {
            setPosition((pos) => ({ ...pos, zoom: MIN_ZOOM }));
            setScaleFactor(MIN_ZOOM);
        } else {
            setScaleFactor((prevScaleFactor) => (prevScaleFactor / 2));
            setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
        }
    };

    const handleMoveEnd = (position) =>{
        setPosition(position);
    };

    return (
        <Box sx={{ height: '100% !important', position: 'relative' }}>
            <Box component="div" sx={{ position: 'absolute', top: '0', left: '0' }}>
                <IconButton onClick={handleHome}>
                    <HomeIcon />
                </IconButton>
            </Box>
            <Box sx={{ position: 'absolute', top: '0', right: '0' }}>
                <IconButton onClick={handleZoomIn} disabled={ scaleFactor === MAX_ZOOM ? true : false }>
                    <ZoomInIcon />
                </IconButton>
                <IconButton onClick={handleZoomOut} disabled={ scaleFactor === MIN_ZOOM ? true : false }>
                    <ZoomOutIcon />
                </IconButton>
            </Box>
            <ComposableMap projection="geoMercator" style={{ width: '100%', aspectRatio: '0.8' }}>
                <ZoomableGroup center={position.coordinates} zoom={position.zoom} maxZoom={MAX_ZOOM} minZoom={MIN_ZOOM} onMove={({ k }) => setScaleFactor(k)} onMoveEnd={handleMoveEnd}>
                    <Geographies geography={geoUrl + "states.json"}>
                    {({ geographies }) => 
                        geographies.map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} fill="none" stroke={strokeColor} strokeWidth={0.2 / scaleFactor} />
                        ))
                    }
                    </Geographies>
                    <Geographies geography={geoUrl + "districts.json"}>
                    {({ geographies }) => 
                        geographies.map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} fill="none" stroke={strokeColor} strokeWidth={0.1 / scaleFactor} />
                        ))
                    }
                    </Geographies>
                    {
                        markers.map((marker, idx) => (
                            scaleFactor <= marker.minZoom || scaleFactor > marker.maxZoom ? null :
                                <Marker key={idx} coordinates={marker.coordinates} onClick={() => marker.zoom === undefined ? handleClick(marker.index) : handleZoom(marker)} >
                                    <g style={{ cursor: 'pointer' }}>
                                        <circle r={6 / scaleFactor} fill={markerColor} />
                                        <text textAnchor="middle" y={ -marker.offset/scaleFactor } fill={strokeColor} style={{ fontSize: `${1 / scaleFactor}rem`, letterSpacing: `${0.1 / scaleFactor}rem` }}>{ marker.name }</text>
                                    </g>
                                </Marker>
                        ))
                    }
                </ZoomableGroup>
            </ComposableMap>
        </Box>
    );
};

export default Map;